import React from "react";
import "./section-two-desk.css";
import scientist from "../../assets/scientist-desk.webp";
import doc from "../../assets/doc-icon.webp";
import szu from "../../assets/szu.webp";
import szuLogo from "../../assets/szu-logo.webp";

const SectionTwoDesk = () => {
  return (
    <div className="section-two-desk">
      <img src={scientist} alt="" className="section-two-desk-img" />
      <h1>a jak je to s kvalitou?</h1>
      <div className="section-two-desk-details">
        <p>
          Kartáček Magnitudal jsme pro Vás nechali důkladně otestovat. Zdravotní
          nezávadnost ověřoval Státní zdravotnický ústav a Strojírenský zkušební
          ústav zase testoval elektrokompatibilitu a celkovou bezpečnost. Ve
          všech testech obstal Magnitudal na jedničku. ZKUŠEBNÍ PROTOKOLY KE
          STAŽENÍ ZDE :
        </p>
        <div className="section-two-desk-doc-icon-container">
          <img src={doc} alt="" />
          <img src={doc} alt="" />
        </div>
        <div className="section-two-desk-logo-container">
          <img src={szu} alt="" />
          <img src={szuLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionTwoDesk;
