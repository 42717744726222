import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import "./section-4a.css";
import drop from "../../assets/drop.webm";
import mag from "../../assets/mag.webm";
import bars from "../../assets/bars.svg";
import needle from "../../assets/needle.svg";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const Section4A = () => {
  const videoRef = useRef();
  const dropVideoRef = useRef();
  const spedometerRef = useRef();
  const topRef = useRef();
  const [isFixed, setIsFixed] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [rotate, setRotate] = useState("25deg");
  const [isPaused, setIsPaused] = useState(false);
  const [spedometerPosition, setSpedometerPosition] = useState(0);
  const { scrollY } = useScroll();
  const springScrollY = useSpring(scrollY);

  const spedometerPositionTransform = useTransform(
    scrollY,
    [spedometerPosition - 200, spedometerPosition + 500],
    ["0px", `-${window.innerWidth / 2.5}px`]
  );

  const textPositionTransform = useTransform(
    scrollY,
    [spedometerPosition + 400, spedometerPosition + 500],
    [0, 1]
  );

  const needlePositionTransform = useTransform(
    scrollY,
    [spedometerPosition - 200, spedometerPosition + 500],
    ["25deg", "-140deg"]
  );

  useEffect(() => {
    setSpedometerPosition(
      spedometerRef.current.getBoundingClientRect().top + window.scrollY
    );
    window.addEventListener("scroll", () => {
      setOpacity(textPositionTransform.current);
      setRotate(needlePositionTransform.current);
      if (
        topRef.current.getBoundingClientRect().top +
          window.scrollY -
          window.scrollY <=
        0
      ) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    });
  }, []);

  return (
    <div className="section-4a" ref={topRef}>
      <div
        className="section-4a-container"
        style={
          isFixed ? { position: "fixed", top: "0" } : { position: "static" }
        }
      >
        <Navbar />
        <div className="section-4a-top">
          <h1 className="section-4a-title">
            podle čeho vybrat <br /> sonický kartáček?
          </h1>
          <p>
            Studie potvrzují, že v případě sonické technologie platí: ČÍM VÍCE,
            TÍM LÉPE Vyšší frekvence vibrací totiž umocňuje sonický efekt. Při
            normální rychlosti se jeví všechny kartáčky stejně, ale pokud se jim
            podíváme na zoubek vysokorychlostní kamerou, je rozdíl patrný na
            první pohled.{" "}
          </p>
        </div>
        <div className="spedometer" ref={spedometerRef}>
          <motion.div style={{ x: spedometerPositionTransform }}>
            <img src={bars} alt="" />
            <img src={needle} alt="" className="img-2" style={{ rotate }} />
            <h4 style={{ opacity }}>ZPOMALENO 1000X</h4>
          </motion.div>
        </div>
        <div style={{ position: "relative" }}>
          <video
            src={drop}
            autoPlay
            muted
            className="drop-video"
            ref={dropVideoRef}
            onTimeUpdate={(e) => {
              if (e.target.currentTime < 8) {
                e.target.playbackRate = 2;
              } else if (Math.floor(e.target.currentTime) === 8) {
                dropVideoRef.current.pause();
                dropVideoRef.current.playbackRate = 1;
              }
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <video
              src={mag}
              autoPlay
              muted
              loop
              ref={videoRef}
              onTimeUpdate={() => {
                if (opacity >= 0.8) {
                  videoRef.current.playbackRate = 2;
                } else {
                  videoRef.current.playbackRate = 10;
                }
                if (
                  Math.ceil(dropVideoRef.current.currentTime) >= 9 &&
                  opacity === 1
                ) {
                  dropVideoRef.current.play();
                }
              }}
              className="brush-video"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4A;
