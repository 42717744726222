import React from "react";
import "./navbar.css";
import logo from "../../assets/logo.webp";

const Navbar = () => {
  return (
    <section className="navbar-container">
      <div className="navbar">
        <img src={logo} alt="" className="logo" />
        <div className="navbar-right">
          <div className="navbar-login">
            <p>jsme online</p>
            <div className="login-image-container"></div>
          </div>
          <p className="number">777 456 522</p>
          <p className="email">dentalweb@dentalweb.cz</p>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
