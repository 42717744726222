import React, { useState, useEffect, useRef } from "react";
import headerImg from "../../assets/header-img.webp";
import "./header.css";
import MouseScroll from "../Mouse Scroll/MouseScroll";
import Navbar from "../Navbar/Navbar";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const Header = () => {
  const ref = useRef();
  const [position, setPosition] = useState(0);
  const { scrollY } = useScroll();
  const springScrollY = useSpring(scrollY, { damping: 10 });

  const move = useTransform(
    springScrollY,
    [position, position + 500],
    ["0", "230px"]
  );

  useEffect(() => {
    window.addEventListener("scroll", () =>
      setPosition(
        ref.current.getBoundingClientRect().top +
          window.scrollY -
          window.innerHeight / 1.5
      )
    );
  }, []);

  return (
    <section className="header">
      <Navbar />
      <img
        className="header-img"
        style={{ opacity: "0.6" }}
        src={headerImg}
        alt=""
      />
      <div className="header-video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/CWs6L8HXrvk"
          title="Dental-Web"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="header-details-top">
        <h1>A V ČEM JE SONICKÝ KARTÁČEK</h1>
        <h1>TAK SKVĚLÝ NÁPAD NA DÁREK?</h1>
        <p>To prostě musíte vyzkoušet na vlastní zuby - rozdíl poznáte hned.</p>
      </div>
      <div className="header-details-bottom" ref={ref}>
        <motion.h1 style={{ y: move }}>JAK TO FUNGUJE? </motion.h1>
        <MouseScroll />
      </div>
    </section>
  );
};

export default Header;
