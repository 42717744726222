import React, { useEffect, useState, useRef } from "react";
import "./first-section.css";
import brush from "../../assets/brush-2.webp";
import Navbar from "../Navbar/Navbar";
import MouseScroll from "../Mouse Scroll/MouseScroll";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const FirstSection = () => {
  // const ref = useRef();
  // const [position, setPosition] = useState(0);
  // const { scrollY } = useScroll();
  // const springScrollY = useSpring(scrollY, { damping: 10 });

  // const move = useTransform(
  //   springScrollY,
  //   [position, position + 500],
  //   ["0px", `215px`]
  // );

  // useEffect(() => {
  //   window.addEventListener("scroll", () =>
  //     setPosition(
  //       ref.current.getBoundingClientRect().top +
  //         window.scrollY -
  //         window.innerHeight / 1.5
  //     )
  //   );
  // }, []);

  return (
    <div className="first-section">
      <Navbar />
      <img src={brush} alt="" className="first-section-img" />
      <div className="first-section-bottom">
        <h1 className="bottom-title">SONICKÁ</h1>
        <h1 className="bottom-title">TECHNOLOGIE</h1>
        <p>
          vytváří směs vody a mikrobublinek, které <br /> pronikají do obtížně
          dostupných míst a<br /> narušují zubní plak.PŘEFORMULOVAT
        </p>
        <div className="bottom">
          <h1>
            PODLE ČEHO VYBRAT
            <br />
            SONICKÝ KARTÁČEK?
          </h1>
          <MouseScroll />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
