import React from "react";
import "./section-8a.css";
import Navbar from "../Navbar/Navbar";
import Scientist from "../../assets/Scientist.webp";
import docIcon from "../../assets/doc-icon.webp";
import szu from "../../assets/szu.webp";
import szuLogo from "../../assets/szu-logo.webp";
import Mouse from "../Mouse Scroll/MouseScroll";

const Section8a = () => {
  return (
    <div className="section-8a">
      <Navbar />
      <div className="section-8a-top">
        <h1>a jak je to s kvalitou?</h1>
        <p>
          Kartáček Magnitudal jsme pro Vás nechali důkladně otestovat. Zdravotní
          nezávadnost ověřoval Státní zdravotnický ústav a Strojírenský zkušební
          ústav zase testoval elektrokompatibilitu a celkovou bezpečnost. Ve
          všech testech obstal Magnitudal na jedničku. ZKUŠEBNÍ PROTOKOLY KE
          STAŽENÍ ZDE :
        </p>
      </div>
      <img src={Scientist} alt="" className="scientist-img" />
      <div className="bottom-doc-container">
        <div className="icon-container">
          <img src={docIcon} alt="" />
          <img src={docIcon} alt="" />
        </div>
        <div className="logo-container">
          <img src={szu} alt="" />
          <img src={szuLogo} alt="" />
        </div>
      </div>
      <div className="scientist-bottom">
        <h2 style={{ marginLeft: "0.5rem" }}>takže není z cukru?</h2>
        <Mouse />
      </div>
    </div>
  );
};

export default Section8a;
