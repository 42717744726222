import React, { useState, useEffect, useRef } from "react";
import "./second-section.css";
import beardMan from "../../assets/beard-man.png";
import MouseScroll from "../Mouse Scroll/MouseScroll";
import mainBrush from "../../assets/brush-1.webp";
import Navbar from "../Navbar/Navbar";
import { AiFillStar } from "react-icons/ai";
import SecondCarousel from "../Carousels/SecondCarousel";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SecondSection = () => {
  const scrollRef = useRef();
  const [position, setPosition] = useState(0);
  const { scrollY } = useScroll();
  const springScrollY = useSpring(scrollY, { damping: 10 });

  const move = useTransform(
    springScrollY,
    [position, position + 500],
    ["0px", `200px`]
  );

  const { ref, inView } = useInView({
    rootMargin: "0px",
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    window.addEventListener("scroll", () =>
      setPosition(
        scrollRef.current.getBoundingClientRect().top +
          window.scrollY -
          window.innerHeight / 1.5
      )
    );
  }, []);

  return (
    <div className="second-section">
      <Navbar />
      <img src={beardMan} alt="" className="second-section-img" />
      <h1 className="title">
        PODLE ČEHO VYBRAT <br /> SONICKÝ KARTÁČEK?
      </h1>
      <div className="sub-title">
        <p>
          Tak to už musí <br /> být o fous lepší, <br /> než ostatní...
        </p>
        <h1>PRO SROVNÁNÍ:</h1>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "2rem",
        }}
      >
        <div className="main-brush-container" ref={ref}>
          <img src={mainBrush} alt="" className="main-brush" />
          <div className="main-brush-detail">
            <p style={{ textDecoration: "underline", fontWeight: "700" }}>
              Magnitudal Quake
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AiFillStar color="lightblue" />
              <p style={{ color: "lightblue" }}>98 %</p>{" "}
              <p style={{ textDecoration: "underline" }}>965 recenzí</p>
            </div>
          </div>
        </div>

        {inView && <SecondCarousel />}
      </div>
      <div className="second-section-bottom" ref={scrollRef}>
        <motion.h1 style={{ y: move }}>
          A PROČ JE MAGNITUDAL <br /> TAK OBLÍBENÝ?
        </motion.h1>
        <MouseScroll />
      </div>
    </div>
  );
};

export default SecondSection;
