import React, { useRef } from "react";
import "./third-section.css";
import manSmilling from "../../assets/man-smilling.webp";
import Navbar from "../Navbar/Navbar";
import RoundedCarousel from "../Carousels/RoundedCarousel";
import ThirdReviewCarousel from "../Carousels/ThirdReviewCarousel";
import MouseScroll from "../Mouse Scroll/MouseScroll";
import { useInView } from "react-intersection-observer";

const ThirdSection = () => {
  const scrollRef = useRef();
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div className="third-section">
      <Navbar />
      <img src={manSmilling} alt="" className="smilling-img" />
      <div className="title">
        <h1>
          A PROČ JE MAGNITUDAL <br /> TAK OBLÍBENÝ?
        </h1>
        <p>
          Posuďte sami, jaké důvody uvádí <br /> naši zákazníci ve více než 1000{" "}
          <br /> ověřených recenzích
        </p>
      </div>
      <RoundedCarousel />
      {inView && <ThirdReviewCarousel />}
      <div className="third-section-bottom" ref={ref}>
        <h1>A BYL BY MAGNITUDAL TO PRAVÉ I PRO MNE?</h1>
        <MouseScroll ref={scrollRef} />
      </div>
    </div>
  );
};

export default ThirdSection;
