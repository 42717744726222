import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-detail-container">
        <p>&copy; Copyright 2019 Dentalweb.cz. Všechna práva vyhrazena</p>
        <p>Wikinomist, s.r.o</p>
        <p>IČ 04692381</p>
      </div>
    </footer>
  );
};

export default Footer;
