import React from "react";
import {
  FirstSection,
  Footer,
  Header,
  HeaderOne,
  SecondSection,
  Section10a,
  Section12a,
  Section4A,
  Section7A,
  Section8a,
  Section9a,
  ThirdSection,
} from "./components";
import {
  HeaderDesk,
  NavbarDesk,
  SectionOneDesk,
  SectionTwoDesk,
} from "./components-desktop";

const App = () => {
  return (
    <main>
      <div style={{ overflowX: "hidden" }} className="main-mobile-view">
        <HeaderOne />
        <Header />
        <FirstSection />
        <Section4A />
        <SecondSection />
        <ThirdSection />
        <Section7A />
        <Section8a />
        <Section9a />
        <Section10a />
        <Section12a />
        <Footer />
      </div>
      <div className="main-desktop-view">
        <NavbarDesk />
        <HeaderDesk />
        <SectionOneDesk />
        <SectionTwoDesk />
      </div>
    </main>
  );
};

export default App;
