import React from "react";
import { CiDesktopMouse2 } from "react-icons/ci";
import { BsChevronDoubleDown } from "react-icons/bs";
import "./mouse-scroll.css";

const MouseScroll = ({ ref }) => {
  return (
    <div
      className="mouse-icon-container"
      style={{ marginRight: "1rem" }}
      onClick={() => {
        ref.current.scrollIntroView();
      }}
    >
      <CiDesktopMouse2 className="mouse-icon" />
      <BsChevronDoubleDown className="mouse-arrow" />
    </div>
  );
};

export default MouseScroll;
