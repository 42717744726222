import React, { useState } from "react";
import "./section-7a.css";
import Navbar from "../../components/Navbar/Navbar";
import women from "../../assets/yellow-woman.webp";
import backpack from "../../assets/backpack-women.webp";
import brushBottom from "../../assets/brush-bottom.webp";
import brushHead1 from "../../assets/brush-head-1.gif";
import battery from "../../assets/battery.webp";
import brush from "../../assets/Header-1-Anim-Images/1.webp";
import brush2 from "../../assets/brush-5.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";
import { motion, AnimatePresence } from "framer-motion";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const Section7A = () => {
  const [isSecondSlideActive, setIsSecondSlideActive] = useState(false);
  const [isThirdSlideActive, setIsThirdSlideActive] = useState(false);

  const brushVariant = {
    hidden: { opacity: 0, x: 0 },
    visible: { opacity: 1, x: 30, transition: { delay: 0.5 } },
  };

  const stagger = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  };

  const textVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  const thirdSlide = {
    hidden: { opacity: 0, y: "50%" },
    animate: { opacity: 1, y: 0, transition: { delay: 0.5 } },
  };

  const heading = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
    exit: { opacity: 0 },
  };

  return (
    <div className="section-7a">
      <Navbar />
      <AnimatePresence>
        {isThirdSlideActive ? (
          <div className="section-7a-top" style={{ paddingTop: "170px" }}>
            <motion.h1 variants={heading} initial="hidden" animate="visible">
              a kolik toho vydrží?
            </motion.h1>
            <motion.p variants={heading} initial="hidden" animate="visible">
              Na jedno nabití i víc jak měsíc při každodenním používání. Takže
              se nemusíte bát ho vzít ssebou na cesty.
            </motion.p>
          </div>
        ) : (
          <motion.div
            variants={heading}
            initial="hidden"
            animate="visible"
            className="section-7a-top"
          >
            <h1>
              a byl by magnitudal to <br /> pravé i pro mne nebo moje <br />{" "}
              blízké?{" "}
            </h1>
            <p>
              Různí lidé mají různé potřeby, proto přichází Magnitudal s širokou
              nabídkou hlavic a provozních módů, ze kterých si zcela jistě
              vyberete tu pravou kombinaci pro Vás.
            </p>
          </motion.div>
        )}
      </AnimatePresence>
      <Swiper
        className="section-7a-swiper"
        navigation
        loop={true}
        slidesPerView={1}
        centeredSlides={true}
        modules={[Navigation, EffectFade]}
        onSlideChange={(swiper) => {
          if (swiper.realIndex === 1) {
            setIsSecondSlideActive(true);
          } else {
            setIsSecondSlideActive(false);
          }
          if (swiper.realIndex === 2) {
            setIsThirdSlideActive(true);
          } else {
            setIsThirdSlideActive(false);
          }
        }}
      >
        <SwiperSlide className="section-7a-swiper-slide">
          <div>
            <img src={women} alt="" className="yellow-woman" />
            <div className="brush-carousel">
              <img src={brushHead1} alt="" className="brush-gif" />
              <img src={brushBottom} alt="" className="brush-bottom" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="section-7a-swiper-slide">
          <AnimatePresence>
            {isSecondSlideActive && (
              <motion.div className="second-slide" exit={{ opacity: 0 }}>
                <motion.img
                  variants={brushVariant}
                  initial="hidden"
                  animate="visible"
                  src={brush}
                  alt=""
                  className="section-7a-brush"
                />
                <motion.div
                  variants={stagger}
                  initial="hidden"
                  animate="visible"
                >
                  <motion.p variants={textVariant}>
                    ► Basic (31 000 vibrací / min) Doporučeno v prvních týdnech
                    používání sonického kartáčku.
                  </motion.p>
                  <motion.p variants={textVariant}>
                    ► Whiten (42 000 vibrací / min) Prevence vytváření barevných
                    pigmentů na povrchu zubů.
                  </motion.p>
                  <motion.p variants={textVariant}>
                    ► Intense (48 000 vibrací / min) Nejvýkonnější mód na
                    usazený zubní plak.
                  </motion.p>
                  <motion.p variants={textVariant}>
                    ► Pulse (plynulé střídaní rychlosti) Efektivní pro
                    odstraňování plaku z mezizubních prostor.
                  </motion.p>
                  <motion.p variants={textVariant}>
                    ► Sensitive (nižší amplituda) Pro citlivé zuby a dásně.
                  </motion.p>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </SwiperSlide>
        <SwiperSlide>
          <AnimatePresence>
            {isThirdSlideActive && (
              <motion.div exit={{ opacity: 0 }} className="third-slide">
                <motion.div
                  variants={thirdSlide}
                  initial="hidden"
                  animate="animate"
                  className="brush-battery-container"
                >
                  <img
                    src={brush2}
                    alt=""
                    className="section-7a-third-slide-img"
                  />
                  <img src={battery} alt="" className="battery" />
                </motion.div>
                <img src={backpack} alt="" className="backpack-woman" />
              </motion.div>
            )}
          </AnimatePresence>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Section7A;
