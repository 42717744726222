import React from "react";
import "./third-review-carousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { thirdCarouselData } from "../../data";

const ThirdReviewCarousel = () => {
  return (
    <Swiper
      loop={true}
      className="third-carousel"
      slidesPerView={1.5}
      centeredSlides={true}
      modules={[EffectCoverflow]}
      effect={"coverflow"}
      onInit={(swiper) => {
        swiper.slideToLoop(9, 1000);
        setTimeout(() => {
          swiper.slideToLoop(3, 500);
        }, 500);
      }}
      coverflowEffect={{
        rotate: 0,
        stretch: 80,
        depth: 700,
        modifier: 1,
        slideShadows: false,
      }}
    >
      {thirdCarouselData.map((item) => {
        return (
          <SwiperSlide className="third-single-slide">
            <div className="third-single-slide-profile">
              <img src={item.image} alt="" />
              <div>
                <h3>{item.name}</h3>
                <p>{item.date}</p>
              </div>
            </div>
            <p className="third-single-slide-review">
              {item.review.substring(0, 100)}...
            </p>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ThirdReviewCarousel;
