import React from "react";
import "./second-carousel.css";
import { Lazy, Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/lazy";
import { AiFillStar } from "react-icons/ai";
import { secondCarouselData } from "../../data";

const Carousel = () => {
  return (
    <Swiper
      loop={true}
      className="carousel"
      slidesPerView={1.1}
      centeredSlides={true}
      modules={[Lazy, Autoplay, EffectCoverflow, Navigation]}
      effect={"coverflow"}
      onInit={(swiper) => {
        setTimeout(() => {
          swiper.slideToLoop(6);
          setTimeout(() => {
            swiper.slideToLoop(2);
          }, 500);
        }, 500);
      }}
      speed={700}
      coverflowEffect={{
        rotate: 0,
        stretch: 80,
        depth: 700,
        modifier: 1,
        slideShadows: false,
      }}
    >
      {secondCarouselData.map((item) => {
        return (
          <SwiperSlide className="single-slide">
            <img src={item.image} alt="" />
            <div className="single-slide-detail">
              <p className="single-slide-detail-title">{item.name}</p>
              <div>
                <AiFillStar color="lightblue" />{" "}
                <p style={{ color: "lightblue" }}>{item.rating} %</p>{" "}
                <p style={{ textDecoration: "underline" }}>
                  {item.number} recenzí
                </p>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
