import React from "react";
import "./section-9a.css";
import Navbar from "../Navbar/Navbar";
import shower from "../../assets/shower.webp";
import waterBrush from "../../assets/water-brush.webp";

const Section9a = () => {
  return (
    <div className="section-9a">
      <Navbar />
      <div className="section-9a-top">
        <h1>takže není z cukru?</h1>
        <p>
          Magnitudal je certifikovaný na IPX7 <br /> Takže se nemusíte bát si ho
          vzít třeba do sprchy
        </p>
      </div>
      <img src={shower} alt="" className="shower-img" />
      <img src={waterBrush} alt="" className="water-brush-img" />
    </div>
  );
};

export default Section9a;
