import React from "react";
import "./navbar-desk.css";
import logo from "../../assets/logo.png";

const NavbarDesk = () => {
  return (
    <nav className="navbar-desk-container">
      <div className="navbar-desk">
        <img src={logo} alt="" className="navbar-desk-logo" />
        <div className="navbar-desk-right">
          <div className="navbar-desk-login">
            <p>jsme online</p>
            <div className="navbar-desk-login-image-container"></div>
          </div>
          <p className="navbar-desk-number">777 456 522</p>
          <p className="navbar-desk-email">dentalweb@dentalweb.cz</p>
        </div>
      </div>
    </nav>
  );
};

export default NavbarDesk;
