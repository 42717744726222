import React, { useState, useEffect } from "react";
import "./section-10a.css";
import Navbar from "../Navbar/Navbar";
import brushPackage from "../../assets/brush-package.webp";
import brushHeads from "../../assets/all-brush-heads.webp";
import { useInView } from "react-intersection-observer";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-car.css";

const Section10a = () => {
  const [odoDeliverValue, setOdoDeliverValue] = useState(0);
  const [odoReturnValue, setOdoReturnValue] = useState(0);

  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  let delivery = new Date();
  delivery.setDate(delivery.getDate() + 1);

  const deliveryResult = delivery.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const deliverMonth = deliveryResult.substring(3, 5);
  const deliverDate = deliveryResult.substring(0, 2);
  const deliverYear = deliveryResult.substring(8, 10);

  const fullDeliverDate = deliverMonth + deliverDate + deliverYear;

  let returning = new Date();
  returning.setDate(returning.getDate() + 120);

  const returningResult = returning.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const returnMonth = returningResult.substring(3, 5);
  const returnDate = returningResult.substring(0, 2);
  const returnYear = returningResult.substring(8, 10);

  const fullReturnDate = returnMonth + returnDate + returnYear;

  useEffect(() => {
    if (inView) {
      setOdoDeliverValue(fullDeliverDate);
      setOdoReturnValue(fullReturnDate);
    }
    // eslint-disable-next-line
  }, [inView]);

  return (
    <div className="section-10a">
      <Navbar />
      <h1 className="section-10a-top">CO JE V BALENÍ ? </h1>
      <img src={brushPackage} alt="" className="brush-package-img" />
      <div>
        <div className="brush-heads-section">
          <div>
            <p>
              Blahopřejeme! Získáváte zdarma 4 náhradní hlavice v hodnotě 690 Kč
            </p>
            <img src={brushHeads} alt="" className="brush-heads-img" />
          </div>
          <div>
            <h1>2995 Skladem</h1>
          </div>
        </div>
      </div>
      <div className="dates" ref={ref}>
        <div>
          <h2>DORUČÍME :</h2>
          <Odometer
            animation="count"
            format="/dd"
            duration={1000}
            value={odoDeliverValue}
          />
        </div>
        <div>
          <h2>VYZKOUŠET A VRÁTIT MŮŽETE DO :</h2>
          <Odometer
            animation="count"
            format="/dd"
            duration={1000}
            value={odoReturnValue}
          />
        </div>
      </div>
    </div>
  );
};

export default Section10a;
