import React from "react";
import "./section-one-desk.css";
import sectionOneImg from "../../assets/section-1-desk-img.webp";

const SectionOneDesk = () => {
  return (
    <div className="section-one-desk">
      <img src={sectionOneImg} alt="" className="section-one-desk-img" />
      <div className="section-one-desk-detail-container">
        <div className="section-one-desk-single-detail">
          <div>
            <h2>Sonická Technologie</h2>
            <p>
              {" "}
              vytváří směs vody a mikrobublinek, které pronikají do obtížně
              dostupných míst a narušují zubní plak.PŘEFORMULOVAT
            </p>
          </div>
        </div>
        <div className="section-one-desk-single-detail">
          <div>
            <h2>a byl by magnitudal to pravé i pro mne?</h2>
            <p>
              {" "}
              Různí lidé mají různé potřeby, proto přichází Magnitudal s širokou
              nabídkou hlavic a provozních módů, ze kterých si zcela jistě
              vyberete tu pravou kombinaci pro Vás.
            </p>
          </div>
        </div>
        <div className="section-one-desk-single-detail">
          <div>
            <h2>a kolik toho vydrží?</h2>
            <p>
              {" "}
              Na jedno nabití i víc jak měsíc při každodenním používání. Takže
              se nemusíte bát ho vzít ssebou na cesty.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOneDesk;
