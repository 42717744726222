import brush2 from "./assets/brush-3.webp";
import brush3 from "./assets/brush-4.webp";
import martin from "./assets/Martin.jpg";
import miroslava from "./assets/Miroslava.jpg";
import stanislav from "./assets/Stainislav.jpg";
import hana from "./assets/Hanah.jpg";
import jana from "./assets/Jana.jpg";

export const roundedData = [
  {
    date: "přidáno 26. října 2022",
    name: "Ověřený zákazník Jirka",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review:
      "Kvalitní zakončení vláken kartáčku. Snadné použití. Je radost si čistit zuby.",
  },
  {
    date: "přidáno 20. října 2022",
    name: "Ověřený zákazník",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review: "Názor napsán v předchozím hodnocení.",
  },
  {
    date: "přidáno 18. října 2022",
    name: "Ověřený zákazník Milan",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review: "Po mé dlouhodobé zkušenosti doporučuji.",
  },
  {
    date: "přidáno 14. října 2022",
    name: "Ověřený zákazník",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review:
      "Skvělý kartáček s dobrým výběrem hlavic. Vydrží nabitý neuvěřitelně dlouho. Potěší šikovné cestovní pouzdro. Koupila jsem ho i dalšímu členu rodiny.",
  },
  {
    date: "přidáno 14. října 2022",
    name: "Ověřený zákazník Martin",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review:
      "Příjemné pro používání díky své nízké váze. Více módů pro čištění. Optimální tvrdost kartáčku Magnisynchro",
  },
  {
    date: "přidáno 12. října 2022",
    name: "Ověřený zákazník Evča",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review:
      "Jsem velice spokojená s produktem už jsem kartáček kupovala svým dětem takže už máme v rodině čtyři a můžu dalším klientům velice doporučit",
  },
  {
    date: "přidáno 3. října 2022",
    name: "Ověřený zákazník",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review:
      "Kartáček naprosto perfektní. Skvěle čistí, je tichý a ma dobrý výběr hlavic. Oproti Philipsu je Magnitudal úplně jina třída. Jsem naprosto spokojen.",
  },
  {
    date: "přidáno 29. září 2022",
    name: "Ověřený zákazník Ilona",
    rating: "100 %",
    stars: 5,
    title: "Doporucuje produkt",
    review:
      "Zatím naprostá spokojenost. Kartáček na pohled hezký, dobře se drží, hlavně dobře čistí. Tišší chod než třeba Oral-B. Výdrž baterie, jak uvádí výrobce - až 1 měsíc, zatím nemohu posoudit. Pořídím i pro děti. Mohu vřele doporučit.",
  },
];

export const secondCarouselData = [
  // {
  //   id: 1,
  //   image: brush1,
  //   name: "Magnitudal Quake",
  //   rating: "98",
  //   number: "965",
  // },
  {
    id: 2,
    image: brush2,
    name: "Philips Sonicare DiamondClean",
    rating: "90",
    number: "260",
  },
  {
    id: 3,
    image: brush3,
    name: "TrueLife SonicBrush UV",
    rating: "95",
    number: "246",
  },
];

export const thirdCarouselData = [
  {
    date: "January 25, 2021",
    image: jana,
    name: "Jana Kubová",
    review:
      "S nákupem přes eshop jsem byla mile překvapena. Rychlost, spolehlivost a super jednání! Se sonickým kartáčkem jsem 100% spokojená. Je to můj první elektrický kartáček a teď vím, že po jiném už nesáhnu. Vyhovují mi i hlavice, jejich hrubost. Už ho používám přes dva měsíce a ještě jsem nemusela dobíjet baterii. Všem doporučuji!!!",
  },
  {
    date: "January 13, 2021",
    image: stanislav,
    name: "Stanislav Presler",
    review:
      "Čištění mě nikdy nebavilo, ale s kartáčkem Magnitudal Quake si to užívám. Cítím jak jsou zuby opravdu pěkně čisté. Je to sice můj první elektrický kartáček, takže nemám s čím porovnat, ale neměnil bych. Na začátku si člověk musí zvyknout, ale pak už je to super. Volit můžete mezi pěti nastaveními. Nelituji investice. Baví mě.Obchod vřele doporučuji. Doručení bylo bez problémů a rychlé.",
  },
  {
    date: "January 9, 2021",
    image: martin,
    name: "Martin Mocoft",
    review:
      "Zubní kartáček Magnitudal Quake jsem pořídil přítelkyni pod stromeček ač jsem věděl že se této technologie trochu obávala. Od prvního vše v pořádku, žádné uvedené přechodové problémy jsme neměli, navíc dodnes jede kartáček na první nabití, zatím na program sensitive. Uvidíme dál...100% spokojenost.",
  },
  {
    date: "January 5, 2021",
    image: miroslava,
    name: "Miroslava Traupová",
    review:
      "Sonický kartáček Magnitudal, je eleantní, náhradní hlavice, jsem spokojená",
  },
  {
    date: "January 5, 2021",
    image: hana,
    name: "Hana Richtrová",
    review: "Sonický zubní kartáček je dárek a jsem nadšená",
  },
];
