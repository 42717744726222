import React, { useState, useEffect } from "react";
import "./header-desk.css";
import headerImg from "../../assets/header-desk-img.webp";
import Odometer from "react-odometerjs";

const HeaderDesk = () => {
  const [odoValue, setOdoValue] = useState(0);

  useEffect(() => {
    setOdoValue(11252);
  }, []);

  return (
    <div className="header-desk">
      <img src={headerImg} alt="" className="header-desk-img" />
      <div className="header-desk-top">
        <h1>v česku už jsme udělali radost UŽ : </h1>
        <div className="header-desk-odometer">
          <Odometer
            animation="count"
            format="d"
            duration={2000}
            value={odoValue}
          />
        </div>
        <p>
          S dárkem, jako je sonický kartáček Magnitudal prostě vedle nešlápnete.
          A i kdyby ano, tak máte 4 měsíce na vrácení (bez udání důvodu). Plnou
          částku vracíme na Váš účet nejpozději druhý den.
        </p>
        <p>To prostě musíte vyzkoušet na vlastní zuby - rozdíl poznáte hned.</p>
        <div className="header-desk-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/CWs6L8HXrvk"
            title="Dental-Web"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="header-desk-right">
        {/* <img src={brushPackage} alt="" /> */}
      </div>

      <div className="header-desk-bottom">
        <h2>A V ČEM JE SONICKÝ KARTÁČEK TAK SKVĚLÝ NÁPAD NA DÁREK?</h2>
      </div>
    </div>
  );
};

export default HeaderDesk;
