const mainReducer = (state, action) => {
  if (action.type === "SECTION1A_START") {
    return { ...state };
  }
  if (action.type === "SECTION1A_PROCESS") {
    return { ...state, section1a: action.payload };
  }
  if (action.type === "SECTION1A_END") {
    return { ...state };
  }
  return { ...state };
};

export default mainReducer;
