import React, { useReducer, useEffect, useContext } from "react";
import reducer from "../reducer/mainReducer";

const MainContext = React.createContext();

var Airtable = require("airtable");
var base = new Airtable({
  apiKey: "keyrZOraSzEm4bceB",
}).base("apppYGwPc2UqsjhKE");

const table = base("mobile");

const initialState = {
  section1a: [],
  section2a: {},
  section3a: {},
  section4a: {},
  section5a: {},
};

export const MainProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSection1a = async () => {
    //dispatch({ type: "SECTION1A_START" });
    table
      .select({
        filterByFormula: `name = "section1a"`,
      })
      .eachPage(
        function page(records, fetchNextPage) {
          dispatch({ type: "SECTION1A_PROCESS", payload: records });
        },
        function done(err) {
          if (err) {
            //dispatch({ type: "SECTION1A_END" });
            return;
          }
        }
      );
  };

  useEffect(() => {
    getSection1a();
  }, []);

  // const getSection1a = async (id) => {
  //   dispatch({ type: "SECTION_1A_START" });
  //   await table.find(id, function (err, record) {
  //     if (err) {
  //       console.error(err);
  //       dispatch({ type: "SINGLE_PRODUCT_END" });
  //       dispatch({ type: "SINGLE_PRODUCT_FAILED" });
  //       return;
  //     }
  //     dispatch({ type: "SINGLE_PRODUCT_SUCCESS" });
  //     dispatch({ type: "SINGLE_PRODUCT", payload: record.fields });
  //   });
  //   dispatch({ type: "SINGLE_PRODUCT_END" });
  // };

  return (
    <MainContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  return useContext(MainContext);
};
