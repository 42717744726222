import React, { useState, useEffect, useRef } from "react";
import "./rounded-carousel.css";
import { AiFillStar } from "react-icons/ai";
import { roundedData } from "../../data";
import { useInView } from "react-intersection-observer";

const Temp = () => {
  const [touchStart, setTouchStart] = useState(0);
  const [angle, setAngle] = useState(0);
  const sliderRef = useRef();
  const { ref, inView, entry } = useInView({
    rootMargin: "-220px",
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `rotateY(360deg)`;
    }
    setTimeout(() => {
      sliderRef.current.style.transform = `rotateY(0deg)`;
    }, 1000);
  }, [inView]);

  if (sliderRef.current) {
    sliderRef.current.style.transform = `rotateY(${angle}deg)`;
  }

  const handleTouchStart = (event) => {
    setTouchStart(event.changedTouches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    if (touchStart < event.changedTouches[0].clientX) {
      setAngle((prev) => prev + 45);
      sliderRef.current.style.transform = `rotateY(${angle}deg)`;
    } else if (touchStart > event.changedTouches[0].clientX) {
      setAngle((prev) => prev - 45);
      sliderRef.current.style.transform = `rotateY(${angle}deg)`;
    }
  };

  return (
    <section
      className="slider"
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}
      ref={ref}
    >
      <div className="spinner" ref={sliderRef}>
        {roundedData.map((item, index) => {
          return (
            <div key={index} className={`content content-${index + 1}`}>
              <p className="date">{item.date}</p>
              <h3 className="name">{item.name}</h3>
              <p className="rating">
                {item.rating}{" "}
                {Array(item.stars)
                  .fill("")
                  .map(() => {
                    return (
                      <span>
                        <AiFillStar />
                      </span>
                    );
                  })}
              </p>
              <p className="product">{item.title}</p>
              <p className="review">{item.review.substring(0, 160)}...</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Temp;
