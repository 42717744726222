import React, { useEffect, useState, useRef } from "react";
import "./header-one.css";
import { Navbar, MouseScroll } from "../../components";
import headerOneImage from "../../assets/header-img-1.webp";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-car.css";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import animImg1 from "../../assets/Header-1-Anim-Images/1.webp";
import gif1 from "../../assets/header-gif.gif";
import gif2 from "../../assets/header-gif-2.gif";
import { useMainContext } from "../../context/mainContext";

const HeaderOne = () => {
  const { section1a } = useMainContext();
  const topRef = useRef();
  const [odoValue, setOdoValue] = useState(0);
  const [topValue, setTopValue] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [scrollValue, setScrollValue] = useState(animImg1);
  const { scrollY } = useScroll();
  const springScrollY = useSpring(scrollY, { damping: 20 });

  const zoomScroll = useTransform(
    springScrollY,
    [topValue, topValue + topValue / 2.5],
    [1, 0]
  );

  useEffect(() => {
    if (shouldAnimate) {
      setScrollValue(gif1);
    } else {
      setScrollValue(gif2);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    window.addEventListener("touchmove", () => {
      if (
        topRef.current.getBoundingClientRect().top +
          topRef.current.getBoundingClientRect().bottom / 1.5 <
        0
      ) {
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
      }
    });
    setTopValue(topRef.current.getBoundingClientRect().bottom + window.scrollY);
  }, []);

  useEffect(() => {
    if (section1a[0]) {
      setOdoValue(section1a[0].fields.header_counter);
    }
  }, [section1a[0]]);

  return (
    <div className="header-one" ref={topRef}>
      <Navbar />
      <img
        src={section1a[0] && section1a[0].fields.main_image[0].url}
        alt=""
        className="header-one-img"
      />
      <div className="anim-img-container">
        <motion.img
          src={scrollValue}
          alt=""
          className="anim-img"
          style={{ y: springScrollY, scale: zoomScroll }}
        />
      </div>
      <div className="header-one-top">
        <h1>{section1a[0] && section1a[0].fields.main_text} </h1>
        <div className="header-counters">
          <div className="header-odometer">
            <Odometer
              animation="count"
              format="d"
              duration={2000}
              value={odoValue}
            />
          </div>
        </div>
        <p>{section1a[0] && section1a[0].fields.sub_text}</p>
      </div>
      <div className="header-one-bottom">
        <h1>{section1a[0] && section1a[0].fields.bottom_text}</h1>
        <MouseScroll />
      </div>
    </div>
  );
};

export default HeaderOne;
