import React, { useState } from "react";
import "./section-12a.css";
import Navbar from "../Navbar/Navbar";
import logo from "../../assets/badge-logo.webp";
import trust from "../../assets/trust.webp";
import chat from "../../assets/chat-photo.webp";
import magda from "../../assets/photo Magda.webp";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";

const Section12a = () => {
  const [isFadeOne, setIsFadeOne] = useState(true);
  const [isFadeTwo, setIsFadeTwo] = useState(true);

  const para1Fade = {
    maskImage: "linear-gradient(180deg, #000 , transparent)",
    WebkitMaskImage: "linear-gradient(180deg, #000 -800%, transparent)",
  };

  const para1NoFade = {
    maskImage: "none",
    WebkitMaskImage: "none",
  };

  const para2Fade = {
    whiteSpace: "nowrap",
    maskImage: "linear-gradient(90deg, #000, transparent)",
    WebkitMaskImage: "linear-gradient(90deg, #000 -100%, transparent)",
  };

  const para2NoFade = {
    whiteSpace: "normal",
    maskImage: "none",
    WebkitMaskImage: "none",
  };

  return (
    <div className="section-12a">
      <Navbar />
      <div className="section-12a-top">
        <h1>
          A PROČ OBJEDNAT Magnitudal TADY NA DENTALWEB A NE TŘEBA v lekárně nebo
          NA ALZE?
        </h1>
      </div>
      <div className="para-one-container">
        {!isFadeOne ? (
          <AiOutlineUp
            className="middle-arrow-up"
            onClick={() => setIsFadeOne(true)}
          />
        ) : (
          <AiOutlineDown
            className="middle-arrow-down"
            onClick={() => setIsFadeOne(false)}
          />
        )}
        <p className="section-12a-top-para">
          U nás máte: <br />
          <span style={isFadeOne ? para1Fade : para1NoFade}>
            <p>
              O 1500,- VÝHODNĚJŠÍ CENU <br />
              DALŠÍ BALENÍ HLAVIC ZDARMA <br /> DOŽIVOTNÍ SLEVU 50 % NA NÁHRADNÍ
              HLAVICE (POSÍLÁME EMAILEM PO NÁKUPU)
            </p>
          </span>
        </p>
      </div>
      <div className="para-two-container">
        {isFadeTwo ? (
          <AiOutlineRight
            className="middle-arrow-right"
            onClick={() => setIsFadeTwo(false)}
          />
        ) : (
          <AiOutlineLeft
            className="middle-arrow-left"
            onClick={() => setIsFadeTwo(true)}
          />
        )}
        <h1 style={isFadeOne ? { marginTop: "-3rem" } : { marginTop: "0" }}>
          můžete nám věřit
        </h1>
        <div
          className="section-12a-middle"
          style={
            isFadeTwo
              ? { transform: "translateX(0)" }
              : { transform: "translateX(-15%)" }
          }
        >
          <img src={trust} alt="" />
          <p style={isFadeTwo ? para2Fade : para2NoFade}>
            Jsme členy Asociace pro elektronickou komerci a držitelem jejich{" "}
            {isFadeTwo && <br />}
            certifikátu kvality. Jejich náročné certifikační řízení je pro Vás{" "}
            {isFadeTwo && <br />}
            zárukou, že se ke svým zákazníkům chováme vždycky fér.
          </p>
        </div>
      </div>
      <div className="above-section-12a-bottom">
        <h2>ZA SVÉ MLUVÍ TAKÉ VÍC NEŽ 1000 RECENZÍ NAŠEHO ESHOPU</h2>
        <img src={logo} alt="" />
      </div>
      <div className="section-12a-bottom">
        <img src={magda} alt="" />
        <img src={chat} alt="" />
      </div>
    </div>
  );
};

export default Section12a;
